@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @layer responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

@font-face {
  font-family: "NimbusSansL";
  src: url("../public/font/NimbusSanL-Reg.otf");
}

.font-NimbusSansL {
  font-family: NimbusSansL, sans-serif;
}

html,
body,
#root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: inherit;
  background-image: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 1s;
  z-index: 10;
  font-family: "NimbusSansL";
}

.loadingScreen__board {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  text-align: center;
  transition: opacity 1s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loadingScreen__title {
  font-size: 1rem;
  color: white;
  margin: 0;
  margin-bottom: 2em;
}

.loadingScreen__button {
  padding: 8px 32px;
  background-color: rgba(0, 0, 0, 0.24);
  color: white;
  border: white solid 1px;
  font-weight: bold;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.loadingScreen__button:hover {
  background-color: rgba(255, 255, 255, 1);
  color: black;
  cursor: pointer;
}

.loadingScreen__button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.loadingScreen__progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12px;
}

.loadingScreen__progress__value {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 1);
  transition: width 0.4s;
}

.loadingScreen--started {
  opacity: 0;
  pointer-events: none;
}

.loadingScreen--started .loadingScreen__board {
  opacity: 0;
}
